/* ---------------------------------
HeroSection
--------------------------------- */

import {
  Avatar,
  Button,
  Image,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { ProgressCard, TestimonialCard } from 'components/molecules'
import { useRouter } from 'next/navigation'
import classNames from 'classnames'
import { motion, useScroll } from 'framer-motion'
import React, { useMemo, useRef } from 'react'
import { useParallax } from '@/hooks'
import PropTypes from 'prop-types'
import { ProgressCardType, TestimonialCardType } from '@/types'

export default function HeroSection({ data }) {
  const { attributes } = data ?? {}
  const {
    title,
    paragraph,
    image,
    ctaButton,
    layout,
    bg: sectionBg,
    progressCard,
    testimonials,
    textAlign,
  } = attributes ?? {}

  const router = useRouter()
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({ target: ref })
  const y = useParallax(scrollYProgress, 550)

  function getLayout(layout) {
    switch (layout) {
      case 'single_col':
        return (
          <div className={'HeroSection__single-col-wrapper'}>
            {testimonials?.length ? (
              <div className={'HeroSection__testimonial-cards'}>
                {testimonials.map((testimonialCard, index) => (
                  <TestimonialCard
                    key={`TestimonialCard__${index}`}
                    className={'HeroSection__testimonial-card'}
                    title={testimonialCard.data?.attributes?.title}
                    subtitle={testimonialCard.data?.attributes?.subtitle}
                    image={testimonialCard.data?.attributes?.image?.url}
                  >
                    {testimonialCard.data?.attributes?.text}
                  </TestimonialCard>
                ))}
              </div>
            ) : null}

            {image ? (
              <Image
                priority
                className={'HeroSection__image'}
                src={image.url}
                width={image.width}
                height={image.height}
                sizes='(min-width: 1440px) 100%, (min-width: 1024px) 50vw, 100vw'
                alt={image.alternativeText}
              />
            ) : null}
          </div>
        )

      default:
      case '2_col':
        return (
          <>
            {image ? (
              <div ref={ref}>
                <Image
                  priority
                  className={'HeroSection__image'}
                  src={image.url}
                  width={image.width}
                  height={image.height}
                  sizes='(min-width: 1440px) 600px, (min-width: 1024px) 50vw, 100vw'
                  alt={image.alternativeText}
                />
              </div>
            ) : null}

            {progressCard ? (
              <motion.div style={{ y }}>
                <ProgressCard
                  className={'HeroSection__progress-card'}
                  figures={progressCard.figures}
                  testimonials={progressCard.testimonials}
                  ctaMessage={progressCard.ctaMessage}
                />
              </motion.div>
            ) : null}
          </>
        )
    }
  }

  const renderedLayout = useMemo(
    () => getLayout(layout),
    [layout, image, progressCard, testimonials]
  )

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'HeroSection',
        `HeroSection--text-align-${textAlign || 'center'}`,
        `HeroSection--layout_${layout || '2_col'}`
      )}
    >
      {/* TODO fine-tune parallax & put ref here */}
      <div className='HeroSection__grid'>
        <div className='HeroSection__grid__col-1'>
          <Avatar
            className={'HeroSection__testimonials'}
            numberOfAvatar={3}
            // Necessary to undo the label
            // since it has a default value.
            label={''}
          />

          {title ? (
            <Title
              mtM
              mbS
              level={1}
              className={'HeroSection__title'}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}

          {paragraph ? (
            <Paragraph className={'HeroSection__paragraph'}>
              {paragraph}
            </Paragraph>
          ) : null}

          {ctaButton ? (
            <Button
              className={'HeroSection__cta-button'}
              variant={'cta'}
              // TODO what if it's a fully qualified URL
              onClick={() => router.push(ctaButton.url)}
            >
              {ctaButton.label}
            </Button>
          ) : null}
        </div>

        <div className='HeroSection__grid__col-2'>{renderedLayout}</div>
      </div>
    </Section>
  )
}

HeroSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['single_col', '2_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      title: PropTypes.string,
      paragraph: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alternativeText: PropTypes.string,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
      ctaButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      progressCard: ProgressCardType,
      testimonials: PropTypes.arrayOf(TestimonialCardType),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
