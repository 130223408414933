/* ---------------------------------
Footer
--------------------------------- */

import { extractStyleProps } from 'utils/styleProps'
import {
  Anchor,
  Button,
  Container,
  IconButton,
  OpeningTitle,
  Paragraph,
  Title,
} from 'components/atoms'
import classNames from 'classnames'
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TIKTOK_URL,
  WHATSAPP_URL,
  X_URL,
  YOUTUBE_URL,
} from 'utils/constants'
import { useRouter } from 'next/navigation'

export default function Footer({
  children,
  className,
  containerStyleProps,
  ...styleProps
}) {
  const router = useRouter()

  return (
    <footer
      className={classNames('Footer', className, extractStyleProps(styleProps))}
    >
      <Container
        className={classNames(
          'Footer__wrapper',
          extractStyleProps(containerStyleProps)
        )}
      >
        <div className='Footer__grid'>
          <div className='Footer__grid__col-1'>
            <img
              className={'Footer__logo'}
              src={'/assets/image/AIRC-logo.svg'}
              alt='Logo di AIRC'
              width={51.2}
              height={80}
            />

            <Title
              negative
              taLeft
              myS
              level={3}
              className='Footer__title'
            >
              Fondazione AIRC per la ricerca sul cancro ETS
            </Title>

            <Paragraph
              negative
              className='Footer__paragraph'
            >
              AIRC è un’ente privato senza fini di lucro che dal AAAA si occupa
              di raccogliere fondi per la ricerca contro il cancro.
            </Paragraph>

            <Button
              mtM
              className={'Footer__cta-button'}
              variant={'cta'}
              onClick={() => router.push('/dona')}
            >
              Dona ora
            </Button>
          </div>

          <div className='Footer__grid__col-2'>
            <OpeningTitle
              className='Footer__opening-title'
              taLeft
              mbM
            >
              Link utili
            </OpeningTitle>

            <ul className='Footer__link-list'>
              <li className='Footer__link-list__item'>
                <Anchor
                  variant={'white'}
                  href={'#'}
                >
                  Sito web
                </Anchor>
              </li>

              <li className='Footer__link-list__item'>
                <Anchor
                  variant={'white'}
                  href={'#'}
                >
                  Privacy Policy
                </Anchor>
              </li>

              <li className='Footer__link-list__item'>
                <Anchor
                  variant={'white'}
                  href={'#'}
                >
                  Cookie Policy
                </Anchor>
              </li>

              <li className='Footer__link-list__item'>
                <Anchor href={'/dona'}>Sostieni la ricerca</Anchor>
              </li>
            </ul>
          </div>

          <div className='Footer__grid__col-3'>
            <OpeningTitle
              className='Footer__opening-title'
              taLeft
              mbM
            >
              Social media
            </OpeningTitle>

            <ul className='Footer__link-list'>
              <li className='Footer__link-list__item'>
                <IconButton
                  variant={'bare'}
                  as={'a'}
                  href={FACEBOOK_URL}
                  ariaLabel={'Visita la pagina Facebook di AIRC'}
                >
                  <FaFacebook
                    size={21}
                    color={'#fff'}
                  />
                </IconButton>
              </li>

              <li className='Footer__link-list__item'>
                <IconButton
                  variant={'bare'}
                  as={'a'}
                  href={X_URL}
                  ariaLabel={'Visita il profilo X di AIRC'}
                >
                  <FaXTwitter
                    size={21}
                    color={'#fff'}
                  />
                </IconButton>
              </li>

              <li className='Footer__link-list__item'>
                <IconButton
                  variant={'bare'}
                  as={'a'}
                  href={YOUTUBE_URL}
                  ariaLabel={'Visita il canale YouTube di AIRC'}
                >
                  <FaYoutube
                    size={21}
                    color={'#fff'}
                  />
                </IconButton>
              </li>

              <li className='Footer__link-list__item'>
                <IconButton
                  variant={'bare'}
                  as={'a'}
                  href={INSTAGRAM_URL}
                  ariaLabel={'Visita il profilo Instagram di AIRC'}
                >
                  <FaInstagram
                    size={21}
                    color={'#fff'}
                  />
                </IconButton>
              </li>

              <li className='Footer__link-list__item'>
                <IconButton
                  variant={'bare'}
                  as={'a'}
                  href={LINKEDIN_URL}
                  ariaLabel={'Visita il profilo Linkedin di AIRC'}
                >
                  <FaLinkedin
                    size={21}
                    color={'#fff'}
                  />
                </IconButton>
              </li>

              <li className='Footer__link-list__item'>
                <IconButton
                  variant={'bare'}
                  as={'a'}
                  href={TIKTOK_URL}
                  ariaLabel={'Visita il profilo TikTok di AIRC'}
                >
                  <FaTiktok
                    size={21}
                    color={'#fff'}
                  />
                </IconButton>
              </li>

              <li className='Footer__link-list__item'>
                <IconButton
                  variant={'bare'}
                  as={'a'}
                  href={WHATSAPP_URL}
                  ariaLabel={'Visita il canale WhatsApp di AIRC'}
                >
                  <FaWhatsapp
                    size={21}
                    color={'#fff'}
                  />
                </IconButton>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </footer>
  )
}
