import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'

// Costanti
const DEBOUNCE_DELAY = 200
const ERROR_MESSAGES = {
  PLACES_SERVICE: 'PlacesService non inizializzato o placeId mancante',
  PLACE_DETAILS: 'Errore nel recupero dei dettagli del luogo',
}

/**
 * Custom hook to initialize and manage Google Places services.
 *
 * @returns {Object} - The services and function to initialize session token.
 * @property {Object} services - The Google Places services.
 * @property {Object|null} services.placesService - The PlacesService instance.
 * @property {Object|null} services.sessionToken - The session token for autocomplete.
 * @property {Object|null} services.autocompleteService - The AutocompleteService instance.
 * @property {Function} initializeSessionToken - Function to initialize a new session token.
 */
function usePlacesService() {
  const placesLibrary = useMapsLibrary('places')
  const [services, setServices] = useState({
    placesService: null,
    sessionToken: null,
    autocompleteService: null,
  })

  const initializeSessionToken = () => {
    if (!placesLibrary) return

    setServices((prev) => ({
      ...prev,
      sessionToken: new placesLibrary.AutocompleteSessionToken(),
    }))
  }

  useEffect(() => {
    if (!placesLibrary) return

    const mapDiv = document.createElement('div')
    mapDiv.style.display = 'none'
    document.body.appendChild(mapDiv)

    try {
      const dummyMap = new google.maps.Map(mapDiv, {
        center: { lat: 0, lng: 0 },
        zoom: 1,
      })

      setServices({
        placesService: new placesLibrary.PlacesService(dummyMap),
        sessionToken: new placesLibrary.AutocompleteSessionToken(),
        autocompleteService: new placesLibrary.AutocompleteService(),
      })
    } catch (error) {
      console.error('Error initializing services:', error)
    }

    return () => mapDiv.remove()
  }, [placesLibrary])

  return { services, initializeSessionToken }
}

/**
 * Custom hook to use Google Places services.
 *
 * @returns {Object} - The state and functions to interact with Google Places services.
 * @property {Array} predictionResults - The list of place predictions.
 * @property {string} inputValue - The current input value.
 * @property {string|null} error - The error message, if any.
 * @property {boolean} isLoading - The loading state.
 * @property {Object|null} formattedAddress - The formatted address details.
 * @property {Function} setInputValue - Function to set the input value and fetch predictions.
 * @property {Function} handleSuggestionClick - Function to handle the click on a suggestion.
 */
export const useGooglePlaces = () => {
  const placesLibrary = useMapsLibrary('places')
  const {
    services: { placesService, sessionToken, autocompleteService },
    initializeSessionToken,
  } = usePlacesService()
  const [state, setState] = useState({
    predictionResults: [],
    inputValue: '',
    error: null,
    isLoading: false,
    formattedAddress: null,
  })

  const debouncedFetch = useMemo(
    () =>
      debounce(async (value) => {
        if (!autocompleteService || !value) {
          setState((prev) => ({
            ...prev,
            predictionResults: [],
            isLoading: false,
          }))
          return
        }

        setState((prev) => ({ ...prev, isLoading: true }))

        try {
          const response = await autocompleteService.getPlacePredictions({
            input: value,
            sessionToken,
          })
          setState((prev) => ({
            ...prev,
            predictionResults: response?.predictions || [],
            error: null,
          }))
        } catch (error) {
          setState((prev) => ({
            ...prev,
            predictionResults: [],
            error: error.message,
          }))
        } finally {
          setState((prev) => ({ ...prev, isLoading: false }))
        }
      }, DEBOUNCE_DELAY),
    [autocompleteService, sessionToken]
  )

  const formatAddress = (placeDetails) => {
    const addressComponents = placeDetails.address_components || []
    const result = {
      ...placeDetails,
      placeId: placeDetails.place_id,
      street: '',
      streetNumber: '',
      city: '',
      province: '',
      postalCode: '',
      formatted_address: placeDetails.formatted_address,
      country: '',
    }

    for (const component of addressComponents) {
      const types = component.types
      if (types.includes('route')) {
        result.street = component.long_name
      }
      if (types.includes('street_number')) {
        result.streetNumber = component.long_name
      }
      if (types.includes('locality')) {
        result.city = component.long_name
      }
      if (types.includes('administrative_area_level_2')) {
        result.province = component.short_name
      }
      if (types.includes('postal_code')) {
        result.postalCode = component.long_name
      }
      if (types.includes('country')) {
        result.country = component.long_name
      }
    }

    return result
  }

  const handleSuggestionClick = useCallback(
    (placeId) => {
      if (!placesService || !placeId) {
        console.error(ERROR_MESSAGES.PLACES_SERVICE)
        return
      }

      setState((prev) => ({ ...prev, isLoading: true }))

      const getDetailsOptions = {
        placeId,
        fields: [
          'address_components',
          'formatted_address',
          'geometry',
          'name',
          'place_id',
        ],
        sessionToken,
      }

      try {
        placesService.getDetails(getDetailsOptions, (placeDetails, status) => {
          if (status === 'OK' && placeDetails) {
            setState((prev) => ({
              ...prev,
              predictionResults: [],
              inputValue: placeDetails.formatted_address ?? '',
              error: null,
              formattedAddress: formatAddress(placeDetails),
            }))

            if (placesLibrary) {
              initializeSessionToken()
            }
          } else {
            throw new Error(`${ERROR_MESSAGES.PLACE_DETAILS}: ${status}`)
          }
        })
      } catch (error) {
        setState((prev) => ({ ...prev, error: error.message }))
      } finally {
        setState((prev) => ({ ...prev, isLoading: false }))
      }
    },
    [placesService, sessionToken, placesLibrary, initializeSessionToken]
  )

  return {
    ...state,
    setInputValue: (value) => {
      setState((prev) => ({ ...prev, inputValue: value }))
      debouncedFetch(value)
    },
    handleSuggestionClick,
  }
}
