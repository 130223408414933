'use client'

import { CheckboxIcon, RequiredFormStar } from '@/svg'
import Link from 'next/link'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { formHasPhoneNumbers } from '../Form/utils/functions'

const componentClassName = 'checkbox'

const valuePicker = (value, defaultValue, formValue, useForm) => {
  const isValidValue = (value) => {
    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      value !== 'undefined'
    )
  }
  if (isValidValue(value)) {
    return value
  }

  if (useForm && isValidValue(formValue)) {
    return formValue
  }

  return defaultValue
}

const Checkbox = ({
  name,
  content,
  onChange = () => {},
  defaultValue = false,
  useFormContextIfAvailable = false,
  config = {},
}) => {
  const formContext = useFormContext()
  const checkboxFromValue = formContext.getValues(name) || defaultValue || false
  const [isChecked, setIsChecked] = useState(
    valuePicker(
      null,
      defaultValue,
      checkboxFromValue,
      useFormContextIfAvailable
    )
  )
  const { isSharedCheckbox, renderContentFn } = sharedCheckboxBuilder(
    name,
    formContext
  )

  const handleChange = (e) => {
    const _isChecked = Boolean(!isChecked)
    setIsChecked(_isChecked)
    onChange(e)
    if (useFormContextIfAvailable && typeof _isChecked === 'boolean') {
      formContext.setValue(name, _isChecked)
    }
  }

  const checkBoxProps = useFormContextIfAvailable
    ? {
        ...formContext.register(name, config),
        onChange: handleChange,
        checked: isChecked,
      }
    : {
        name,
        checked: isChecked,
        onChange: handleChange,
      }

  if (content === null || renderContentFn === null) return null
  return (
    <div
      className={`${componentClassName}_wrapper`}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          const checkboxInput = window.document.querySelector(
            `input[name=${name}]`
          )
          checkboxInput.click()
        }
      }}
    >
      <CheckboxIcon
        checked={isChecked}
        onChange={handleChange}
      />
      <label className={`${componentClassName}_checkbox `}>
        <input
          className={`${componentClassName}_input `}
          type='checkbox'
          name={name}
          checked={isChecked}
          tabIndex={-1}
          {...checkBoxProps}
        />
        <span
          htmlFor={name}
          className={`${componentClassName}_label `}
        >
          {isSharedCheckbox ? renderContentFn() : content}
        </span>
      </label>
    </div>
  )
}

/*
 ? This function is used to define the content of the shared checkboxes
 ? Shared means that these checkboxes have predefined content or behavior
 ? that is common across different parts of the application.
*/
function sharedCheckboxBuilder(name, formContext) {
  if (!name) return { isSharedCheckbox: false, name: null, content: null }

  const sharedCheckboxes = ['privacy', 'termsAndConditions', 'newsletter']
  const isSharedCheckbox = sharedCheckboxes.includes(name)

  if (!isSharedCheckbox) return { isSharedCheckbox, name }

  switch (name) {
    case 'privacy':
      return {
        name: 'privacy',
        renderContentFn: renderPrivacyContent,
        isSharedCheckbox,
      }
    case 'termsAndConditions':
      // Must check if form donatorCellphone and donatorCompanyCellphone are different from their default value
      // If they are different, then the checkbox exists, otherwise not
      if (formContext === undefined)
        return { isSharedCheckbox, name, renderContentFn: null }
      const mustRenderCheckbox = formHasPhoneNumbers(formContext.getValues())
      if (!mustRenderCheckbox) {
        return { isSharedCheckbox, name, renderContentFn: null }
      }
      return {
        name: 'termsAndConditions',
        renderContentFn: renderTermsAndConditionsContent,
        isSharedCheckbox,
      }
    case 'newsletter':
      return {
        name: 'newsletter',
        renderContentFn: renderNewsletterContent,
        isSharedCheckbox,
      }
    default:
      return { isSharedCheckbox, name, renderContentFn: null }
  }
}

const renderPrivacyContent = () => {
  return (
    <div className={'checkbox__privacy-content'}>
      <div className={'checkbox__privacy-content_text'}>
        Confermo di aver letto e compreso la{' '}
        <Link
          tabIndex={0}
          href='https://donazione.airc.it/privacy'
          target='_blank'
        >
          informativa sul trattamento dei dati personali
          <RequiredFormStar />
        </Link>
      </div>
    </div>
  )
}

const renderTermsAndConditionsContent = () => {
  return (
    <span>
      Acconsento a ricevere comunicazioni tramite telefonate, sms o strumenti di
      messaggistica istantanea, nonché partecipare a indagini per migliorare le
      attività di AIRC?
    </span>
  )
}

const renderNewsletterContent = () => {
  return (
    <span>
      Desideri iscriverti alla newsletter e ricevere comunicazioni da parte di
      AIRC a mezzo mail?
    </span>
  )
}

export default Checkbox
