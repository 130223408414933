/* ---------------------------------
EditorialSection
--------------------------------- */

import {
  Button,
  Image,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { TestimonialCard } from 'components/molecules'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRouter } from 'next/navigation'
import { TestimonialCardType } from '@/types'

export default function EditorialSection({ data }) {
  const { attributes } = data ?? {}
  const {
    openingTitle,
    title,
    paragraph,
    image,
    ctaButton,
    testimonialCard,
    layout,
    bg: sectionBg,
    textAlign,
  } = attributes ?? {}

  const router = useRouter()

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'EditorialSection',
        layout === 'invert_cols' && 'EditorialSection--layout_invert_cols',
        `EditorialSection--text-align-${textAlign || 'center'}`
      )}
    >
      <div className='EditorialSection__grid'>
        <div className='EditorialSection__grid__col-1'>
          {openingTitle ? (
            <OpeningTitle mbS>{openingTitle}</OpeningTitle>
          ) : null}

          {title ? (
            <Title
              mt0
              mbS
              level={2}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}

          {paragraph ? <Paragraph mbL>{paragraph}</Paragraph> : null}

          {testimonialCard ? (
            <div style={{ marginBottom: 'var(--Spacing-M)' }}>
              <TestimonialCard
                className={'EditorialSection__testimonial-card--lg_show'}
                title={testimonialCard.data?.attributes?.title}
                subtitle={testimonialCard.data?.attributes?.subtitle}
                image={testimonialCard.data?.attributes?.image?.url}
              >
                {testimonialCard.data?.attributes?.text}
              </TestimonialCard>
            </div>
          ) : null}

          {ctaButton ? (
            <Button
              className={'EditorialSection__cta-button'}
              variant={'cta'}
              // TODO what if it's a fully qualified URL
              onClick={() => router.push(ctaButton.url)}
            >
              {ctaButton.label}
            </Button>
          ) : null}
        </div>

        <div className='EditorialSection__grid__col-2'>
          {image ? (
            <Image
              styleProps={{ mbL: Boolean(testimonialCard) }}
              className={'EditorialSection__image'}
              src={image.url}
              width={image.width}
              height={image.height}
              sizes='(min-width: 1440px) 600px, (min-width: 1024px) 50vw, 100vw'
              alt={image.alternativeText}
            />
          ) : null}

          {testimonialCard ? (
            <TestimonialCard
              className={'EditorialSection__testimonial-card--lg_hide'}
              title={testimonialCard.data?.attributes?.title}
              subtitle={testimonialCard.data?.attributes?.subtitle}
              image={testimonialCard.data?.attributes?.image?.url}
            >
              {testimonialCard.data?.attributes?.text}
            </TestimonialCard>
          ) : null}
        </div>
      </div>
    </Section>
  )
}

EditorialSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols', 'single_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
      ctaButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      testimonialCard: TestimonialCardType,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
