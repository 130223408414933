'use client'

import CodiceFiscale from 'codice-fiscale-js'

import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IoCheckmarkOutline } from 'react-icons/io5'
import { toast } from 'react-toastify'
import Input from '../Input'
import Modal from '../Modal'
import Select from '../Select'

function TaxCodeCalculatorModal({
  isOpen,
  onClose,
  name,
  classNames = {
    modalOverlay: 'modal-overlay',
    modalContent: 'modal-content',
    closeButton: 'close-button',
  },
}) {
  const formContext = useFormContext()
  const currentFormValues = formContext.watch()

  const [taxCodeFormValues, setTaxCodeFormValues] = useState(() => ({
    firstName: currentFormValues.donatorFirstName || '',
    lastName: currentFormValues.donatorLastName || '',
    genderTaxCode: currentFormValues.donatorGender || '',
    birthPlace: currentFormValues.donatorCity || '',
    province: currentFormValues.donatorProvince || '',
  }))
  const [taxCode, setTaxCode] = useState('')

  const handleTaxCodeChange = (e) => {
    setTaxCodeFormValues({
      ...taxCodeFormValues,
      [e.target.name]: e.target.value,
    })
  }

  const notifyOnFail = (error) => {
    const errorLabel = String(error).replace('Error: ', '')
    toast.error(
      `Errore!
      ${errorLabel}`,
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      },
      { delay: 500 }
    )
  }

  const notifyOnSuccess = () => {
    toast.success(
      'Codice fiscale calcolato con successo',
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: <IoCheckmarkOutline />,
      },
      { delay: 500 }
    )
  }

  const handleCalculate = () => {
    const {
      firstName: _firstName,
      lastName: _lastName,
      genderTaxCode: _genderTaxCode,
      date,
      birthPlace,
      province,
    } = taxCodeFormValues

    const firstName = _firstName || currentFormValues.donatorFirstName
    const lastName = _lastName || currentFormValues.donatorLastName
    const genderTaxCode =
      _genderTaxCode !== 'default'
        ? _genderTaxCode
        : currentFormValues.donatorGender

    try {
      if (
        !firstName ||
        !lastName ||
        !genderTaxCode ||
        !date ||
        !birthPlace ||
        !province
      ) {
        throw new Error('Compila tutti i campi per calcolare il codice fiscale')
      }

      const [year, month, day] = date.split('-')
      const taxCode = new CodiceFiscale({
        name: firstName,
        surname: lastName,
        gender: genderTaxCode,
        day: Number(day),
        month: Number(month),
        year: Number(year),
        birthplace: birthPlace,
        birthplaceProvincia: province,
      })

      if (taxCode?.code) {
        setTaxCode(taxCode.code)
        if (formContext) {
          formContext.setValue(name, taxCode.code)
        }
        notifyOnSuccess()
        onClose()
      }
    } catch (error) {
      console.error('Errore: ', error)
      notifyOnFail(error)
    }
  }

  const { firstName, lastName, genderTaxCode, date, birthPlace, province } =
    taxCodeFormValues

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      classNames={{
        modalOverlay: 'modal-overlay',
        modalContent: 'modal-content',
        closeButton: 'close-button',
      }}
    >
      <div className={'tax-code-calculator'}>
        <h2
          className={'tax-code-calculator__title'}
          id='modal-title'
        >
          Calcola il codice fiscale
        </h2>

        <div className={'tax-code-calculator__form'}>
          <Input
            label={'Nome'}
            name={'firstName'}
            type={'text'}
            defaultValue={currentFormValues.donatorFirstName || ''}
            value={firstName ?? currentFormValues.donatorFirstName}
            onChange={handleTaxCodeChange}
            useFormContextIfAvailable={false}
            disableQueryState={true}
            placeholder={'Nome'}
            config={{
              pattern: {
                value: 'only-letters',
                message: 'Inserisci solo lettere',
              },
            }}
          />
          <Input
            label={'Cognome'}
            name={'lastName'}
            type={'text'}
            defaultValue={currentFormValues.donatorLastName || ''}
            value={lastName ?? currentFormValues.donatorLastName}
            onChange={handleTaxCodeChange}
            useFormContextIfAvailable={false}
            disableQueryState={true}
            placeholder={'Cognome'}
          />
          <Select
            label={'Genere'}
            name={'genderTaxCode'}
            selectOptions={[
              {
                value: 'default',
                label: 'Genere',
                disabled: true,
              },
              {
                value: 'M',
                label: 'Maschio',
              },
              {
                value: 'F',
                label: 'Femmina',
              },
            ]}
            defaultValue={currentFormValues.donatorGender || 'M'}
            value={genderTaxCode ?? currentFormValues.donatorGender}
            useFormContextIfAvailable={false}
            disableQueryState={true}
            placeholder={'Genere'}
            onChange={handleTaxCodeChange}
          />

          <Input
            label={'Data di nascita'}
            name={'date'}
            type={'date'}
            value={date}
            onChange={handleTaxCodeChange}
            useFormContextIfAvailable={false}
            disableQueryState={true}
            placeholder={'GG/MM/AAAA'}
            config={{
              min: '1900-01-01',
              max: new Date().toISOString().split('T')[0],
              required: {
                value: true,
                message: 'Il nome è obbligatorio',
              },
            }}
          />
          <Input
            label={'Città'}
            name={'birthPlace'}
            type={'text'}
            value={birthPlace}
            useFormContextIfAvailable={false}
            onChange={handleTaxCodeChange}
            disableQueryState={true}
            placeholder={'Luogo di nascita'}
          />
          <Input
            label={'Provincia'}
            name={'province'}
            type={'text'}
            value={province}
            useFormContextIfAvailable={false}
            disableQueryState={true}
            onChange={handleTaxCodeChange}
            placeholder={'Provincia es. MI'}
          />
        </div>
        <button
          className={'tax-code-calculator__calculate-button'}
          onClick={handleCalculate}
        >
          Calcola Codice Fiscale
        </button>
      </div>
    </Modal>
  )
}

export default TaxCodeCalculatorModal
