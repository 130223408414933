'use client'

/* ---------------------------------
VideoCard
--------------------------------- */

import { Card, PlayButton } from 'components/atoms'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import dynamic from 'next/dynamic'
import { useState } from 'react'

// NOTE may handle different types if needed with dynamic import
const ReactPlayer = dynamic(() => import('react-player/file'), { ssr: false })

export default function VideoCard({ video, ...styleProps }) {
  const [playing, setPlaying] = useState(false)

  const { src, poster } = video ?? {}

  return (
    <Card
      variant={'bare'}
      className={classNames('VideoCard', extractStyleProps(styleProps))}
    >
      <div className='VideoCard__container'>
        <ReactPlayer
          url={src}
          playing={playing}
          onPlay={() => setPlaying(true)}
          onPause={() => {
            // after the initial play via the PlayButton,
            // the play state would remain `true`, so we're
            // resetting it when the video pauses.
            if (playing) setPlaying(false)
          }}
          width='100%'
          height={'100%'}
          loop
          muted
          controls
          playsinline
          // ONLY WORKS IN LIGHT MODE
          playIcon={
            <PlayButton
              onClick={() => setPlaying(true)}
              className={'VideoCard__play-button'}
            />
          }
          light={poster}
        />
      </div>
    </Card>
  )
}
