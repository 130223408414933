const RequiredFormStar = () => {
  return (
    <svg
      className='required-form-star'
      xmlns='http://www.w3.org/2000/svg'
      width='6'
      height='7'
      viewBox='0 0 6 7'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 0.75C3.13807 0.75 3.25 0.861929 3.25 1V2.89645L4.59099 1.55546C4.68862 1.45783 4.84691 1.45783 4.94454 1.55546C5.04218 1.65309 5.04218 1.81138 4.94454 1.90901L3.60355 3.25H5.5C5.63807 3.25 5.75 3.36193 5.75 3.5C5.75 3.63807 5.63807 3.75 5.5 3.75H3.60355L4.94454 5.09099C5.04217 5.18862 5.04217 5.34691 4.94454 5.44454C4.84691 5.54217 4.68862 5.54217 4.59099 5.44454L3.25 4.10355V6C3.25 6.13807 3.13807 6.25 3 6.25C2.86193 6.25 2.75 6.13807 2.75 6V4.10355L1.40901 5.44454C1.31138 5.54217 1.15309 5.54217 1.05546 5.44454C0.957826 5.34691 0.957826 5.18862 1.05546 5.09099L2.39645 3.75H0.5C0.361929 3.75 0.25 3.63807 0.25 3.5C0.25 3.36193 0.361929 3.25 0.5 3.25H2.39645L1.05546 1.90901C0.957825 1.81138 0.957825 1.65309 1.05546 1.55546C1.15309 1.45783 1.31138 1.45783 1.40901 1.55546L2.75 2.89645V1C2.75 0.861929 2.86193 0.75 3 0.75Z'
        fill='#00ACDE'
      />
    </svg>
  )
}

export default RequiredFormStar
