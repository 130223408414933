'use client'

import { ErrorCheck, ValidCheck } from '@/svg'
import { Fragment, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FaChevronDown } from 'react-icons/fa6'
import { COUNTRIES } from '../../../utils/constants'

const Select = ({
  label = 'Ricorrenza della donazione',
  classNames = {
    wrapper: 'select-wrapper',
    select: 'select',
    option: 'select_option',
    icon: 'select_icon',
  },
  name = 'input-default',
  defaultValue = '',
  isHidden = false,
  disableQueryState = false,
  config = null,
  placeholder = '',
  type = 'text',
  selectOptions = [],
  useFormContextIfAvailable = false,
  value = '',
  onChange: _onChange = () => {},
  triggerError = false,
  ...rest
}) => {
  const { register, setValue, getValues } = useFormContext()
  const currentFormSelectValue = getValues(name)
  const { onChange, ref } = register(name)

  const [selectValue, setSelectValue] = useState(
    useFormContextIfAvailable
      ? value || currentFormSelectValue || defaultValue
      : value || defaultValue
  )

  const handleChange = (e) => {
    const newValue = e.target.value
    setSelectValue(newValue)
    _onChange(e)
    onChange(e)
  }

  const isRequired = config?.required || false
  const isCountryList = selectOptions === 'COUNTRIES_LIST'
  const isPopulated =
    selectValue !== null &&
    selectValue !== '' &&
    selectValue !== undefined &&
    selectValue !== 'default'

  const customConfigIfRequired = isRequired
    ? {
        validate: (value) =>
          value !== 'default' || 'Seleziona un opzione valida',
      }
    : {}

  const selectProps =
    useFormContextIfAvailable && currentFormSelectValue !== undefined
      ? {
          ...register(name, {
            ...config,
            ...customConfigIfRequired,
          }),
        }
      : {}

  const renderCountryList = () => (
    <Fragment>
      <option
        className={classNames.option}
        value='default'
        disabled
      >
        {placeholder || 'Seleziona un paese'}
      </option>
      {COUNTRIES.map((o) => (
        <option
          className={classNames.option}
          key={o.id}
          value={o.id}
        >
          {o.label}
        </option>
      ))}
    </Fragment>
  )

  return (
    <div className={classNames.wrapper}>
      {triggerError !== false ? <ErrorCheck /> : null}
      {isPopulated ? <ValidCheck /> : null}
      <select
        {...selectProps}
        tabIndex={0}
        name={name}
        ref={ref}
        aria-label={`Seleziona ${label}`}
        className={`${classNames.select} ${triggerError ? 'select_error' : ''}
       ${isPopulated ? 'select_populated' : ''} `}
        value={selectValue}
        onChange={handleChange}
      >
        {isCountryList
          ? renderCountryList()
          : selectOptions.map((o, index) => (
              <option
                className={classNames.option}
                key={index}
                tabIndex={o.disabled ? -1 : 0}
                value={o.value}
                disabled={o.disabled}
              >
                {o.label}
              </option>
            ))}
      </select>
      <FaChevronDown className={classNames.icon} />
    </div>
  )
}

export default Select
