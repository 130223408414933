'use client'
import { useAddressAutocomplete } from '@/hooks/useAddressAutocomplete'
import { ErrorCheck, ValidCheck } from '@/svg'
import CodiceFiscale from 'codice-fiscale-js'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IoCloseOutline, IoSparklesSharp } from 'react-icons/io5'
import CustomDropdown from '../CustomDropdown'
import TaxCodeCalculatorModal from '../Modal/TaxCodeCalculatorModal'

const Input = ({
  label = 'Ricorrenza della donazione',
  classNames = {
    input: 'input',
    icon: 'input_icon',
  },
  name = 'input-default',
  defaultValue = '',
  isHidden = false,
  disableQueryState = false,
  config = null,
  placeholder = '',
  type = 'text',
  useFormContextIfAvailable = false,
  handleKeyPress = () => {},
  onChange: _onChange = null,
  onBlur: _onBlur = null,
  hasCloseIcon = false,
  onCloseIconClick = () => {},
  value: _value = null,
  className = '',
  uiOptions = {},
  triggerError = false,
  ...rest
}) => {
  const hasRendered = useRef(false)
  const inputRef = useRef(null)
  const formContext = useFormContext()
  const currentFormInputValue = formContext?.getValues(name)

  const [isTaxCodeModallOpen, setIsTaxCodeModal] = useState(false)

  const isDatePick = type === 'date'
  const isTaxCode = config?.pattern?.value.startsWith('tax-code')
  const isAddress = config?.pattern?.value === 'address'
  const isCompanyTaxCode = config?.pattern?.value === 'tax-code-company'
  const isProvinceIndividual =
    name.includes('rovinc') || label.includes('rovinc')

  const {
    isVisible,
    setIsVisible,
    dropdownPosition,
    setDropdownPosition,
    predictionResults,
    handleSuggestionClick,
    setAddressInputValue,
    formattedAddress,
  } = useAddressAutocomplete({ formContext, name, uiOptions, isAddress })

  const [inputValue, setInputValue] = useState(
    valuePicker(
      _value,
      defaultValue,
      currentFormInputValue,
      useFormContextIfAvailable
    )
  )
  const isFieldPopulated = inputValue !== null && inputValue !== ''

  const handleChange = async (e) => {
    const _value = e.target.value
    if (isAddress) {
      setAddressInputValue(_value)
    }
    if (_onChange) {
      _onChange(e)
    }
    if (useFormContextIfAvailable) {
      formContext.setValue(name, _value)
    }
    setInputValue(_value)
    return _value
  }

  const handleBlur = (e) => {
    if (_onBlur) {
      _onBlur(e)
    }
    if (isAddress) {
      setAddressInputValue('')
      setIsVisible(false)
    }
  }

  const handleFocus = () => {
    if (inputRef.current === null || !isAddress) return
    // we need to calculate the position of the dropdownbased on the input element position
    // why? The complex context of input could not allow a dropdown to be placed
    // in the right position. So we have to work using absolute units.
    const rect = inputRef.current.getBoundingClientRect()
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
    })
    setIsVisible(true)
  }

  // Google Places API autocomplete suggestion
  const handleAddressSuggestionClick = (placeId) => {
    try {
      if (!handleSuggestionClick) {
        throw new Error('handleSuggestionClick is not defined')
        return null
      }
      const _formattedAddress = handleSuggestionClick(placeId)
      return _formattedAddress
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const inputProps = buildInputProps({
    isDatePick,
    config,
    formContext,
    useFormContextIfAvailable,
    name,
    inputValue,
    handleChange,
    isProvinceIndividual,
    isTaxCode,
    isCompanyTaxCode,
  })

  // Set the value in the form context if the component has not rendered yet
  //  Why? To handle browser field autofillers.
  // Infact they actually update input values without triggering onChange event
  useEffect(() => {
    if (!hasRendered.current) {
      hasRendered.current = true
      if (useFormContextIfAvailable && formContext) {
        formContext.setValue(
          name,
          valuePicker(_value, defaultValue, currentFormInputValue, true)
        )
      }
    }
  }, [])

  // Ensure the input value is always in sync with the form context and viceversa
  useEffect(() => {
    const valuePicked = valuePicker(
      _value,
      defaultValue,
      currentFormInputValue,
      useFormContextIfAvailable
    )
    if (
      valuePicked !== inputValue &&
      valuePicked !== null &&
      valuePicked !== '' &&
      valuePicked !== undefined
    ) {
      setInputValue(valuePicked)
    }
  }, [
    _value,
    defaultValue,
    currentFormInputValue,
    useFormContextIfAvailable,
    inputValue,
  ])

  // Handle click outside the input to close the Dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isLiWithCustomClass =
        event.target.classList.contains('dropdown-list-item')
      if (isLiWithCustomClass) return
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setIsVisible])

  return (
    <div className={'input-wrapper'}>
      {isFieldPopulated ? <ValidCheck /> : null}
      {triggerError !== false ? <ErrorCheck /> : null}
      <input
        data-lpignore='true'
        className={`${classNames.input}
        ${isAddress && isVisible && predictionResults.length > 0 ? 'input_expanded' : ''}
        ${isProvinceIndividual ? 'input_province' : ''}
        ${triggerError ? 'input_error' : ''}
        ${isFieldPopulated && !triggerError ? 'input_populated' : ''}
        ${isTaxCode ? 'input_tax-code' : ''} ${className}
          `}
        placeholder={isDatePick && !!inputValue ? '' : placeholder}
        type={isHidden ? 'hidden' : type}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...inputProps}
        {...rest}
        ref={inputRef}
      />
      <CustomDropdown
        isVisible={isVisible && predictionResults.length > 0}
        position={dropdownPosition}
      >
        {predictionResults.map(({ place_id, description }) => (
          <li
            key={place_id}
            className='dropdown-list-item'
            onClick={() => handleAddressSuggestionClick(place_id)}
          >
            {description}
          </li>
        ))}
      </CustomDropdown>

      {isTaxCode && !isCompanyTaxCode ? (
        <IoSparklesSharp
          className={classNames.icon}
          tabIndex={0}
          role={'button'}
          aria-label={'Calcola il codice fiscale'}
          onClick={() => setIsTaxCodeModal(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsTaxCodeModal(true)
            }
          }}
        />
      ) : null}
      {hasCloseIcon ? (
        <IoCloseOutline
          id={'close-icon'}
          className={classNames.icon}
          onClick={() => {
            setInputValue(null)
            if (formContext) {
              formContext.setValue(name, '')
            }
            onCloseIconClick()
          }}
        />
      ) : null}
      {isTaxCode ? (
        <TaxCodeCalculatorModal
          name={name}
          isOpen={isTaxCodeModallOpen}
          onClose={() => setIsTaxCodeModal(false)}
        />
      ) : null}
    </div>
  )
}
function valuePicker(value, defaultValue, formValue, useForm) {
  const isValidValue = (value) => {
    return value !== undefined && value !== null && value !== ''
  }
  if (useForm && isValidValue(formValue)) {
    return formValue
  }
  if (isValidValue(value)) {
    return value
  }

  return defaultValue
}

function parsePattern(pattern) {
  switch (pattern) {
    case 'only-letters':
      return /^[a-zA-ZÀ-ÿ\s'-]+$/
    case 'only-numbers':
      return /^[0-9]+$/
    case 'email':
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    case 'address':
      return /^[a-zA-ZÀ-ÿ\s'-]+$/
    case 'cap':
      return /^[0-9]{4,5}$/
    case 'iban':
      return /^([A-Z]{2})(\d{2})([A-Z\d]{4,34})$/
    default:
      return null
  }
}

function buildInputProps({
  isDatePick,
  config,
  formContext,
  useFormContextIfAvailable,
  name,
  inputValue,
  handleChange,
  isProvinceIndividual,
  isTaxCode,
  isCompanyTaxCode,
}) {
  // Build date picker specific props
  const datePickProps = isDatePick
    ? {
        min: config?.min || '1900-01-01',
        max: config?.max || '',
      }
    : {}

  // Build province specific props
  const provinceProps = isProvinceIndividual
    ? {
        maxLength: '2',
      }
    : {}

  // Process pattern configuration
  const processedConfig = config?.pattern?.value
    ? {
        ...config,
        pattern: {
          ...config.pattern,
          value: parsePattern(config.pattern.value),
        },
        // Add tax code validation if needed
        ...(isTaxCode && !isCompanyTaxCode
          ? {
              validate: (value) => {
                if (!value) return 'Codice fiscale non valido'
                return CodiceFiscale.check(value) || 'Codice fiscale non valido'
              },
            }
          : {}),
      }
    : config

  // Return either form context props or standard props
  if (formContext && useFormContextIfAvailable) {
    const { register } = formContext
    return {
      ...datePickProps,
      ...register(name, processedConfig),
      onChange: handleChange,
      value: inputValue,
      ...provinceProps,
    }
  }

  return {
    name,
    value: inputValue,
    onChange: handleChange,
    ...datePickProps,
    ...provinceProps,
  }
}

export default Input
