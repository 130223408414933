const ErrorCheck = () => {
  return (
    <svg
      width='10'
      height='11'
      viewBox='0 0 10 11'
      className='error-check'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <circle
          id='Ellipse 6'
          cx='5'
          cy='5.5'
          r='5'
          fill='#FF1F1F'
        />
        <path
          id='Vector 10'
          d='M7 3.5L3.00002 7.49998'
          stroke='white'
          strokeLinecap='round'
        />
        <path
          id='Vector 11'
          d='M3 3.5L6.99998 7.49998'
          stroke='white'
          strokeLinecap='round'
        />
      </g>
    </svg>
  )
}

export default ErrorCheck
