'use client'
import ReactDOM from 'react-dom'

const CustomDropdown = ({ isVisible, children, position }) => {
  if (!isVisible) return null

  return ReactDOM.createPortal(
    <ul
      className='dropdown-list'
      style={{
        top: position.top - 1,
        left: position.left,
        width: position.width,
      }}
    >
      {children}
    </ul>,
    document.body
  )
}

export default CustomDropdown
