import { Spinner } from '@/components/atoms'
import { IoWarning } from 'react-icons/io5'
import Modal from '../Modal'

const ErrorModal = ({
  error,
  isOpen,
  onClose,
  copy = {
    title: 'Errore',
    body: 'Qualcosa è andato storto',
    cta: 'Chiudi',
  },
  classNames = {
    modalOverlay: 'modal-overlay',
    modalContent: 'modal-content error-modal',
    closeButton: 'close-button',
  },
  isErrror = true,
  isLoading = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeLabel={copy.cta}
      classNames={classNames}
    >
      <div className='error-modal__content'>
        {isErrror ? <IoWarning className='error-modal__content__icon' /> : null}
        {isLoading ? <Spinner hasText={false} /> : null}
        <h2 className='error-modal__content__title'>{copy.title}</h2>
        <p className='error-modal__content__message'>{copy.body}</p>
      </div>
    </Modal>
  )
}

export default ErrorModal
