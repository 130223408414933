/* ---------------------------------
TestimonialCard
--------------------------------- */

import { Callout, Testimonial } from 'components/atoms'
import Card from '../../atoms/Card'
import classNames from 'classnames'

export default function TestimonialCard({
  children,
  className,
  ...testimonial
}) {
  return (
    <Card className={classNames('TestimonialCard', className)}>
      <Testimonial
        className={'TestimonialCard__testimonial'}
        {...testimonial}
      />

      <Callout className={'TestimonialCard__callout'}>{children}</Callout>
    </Card>
  )
}
