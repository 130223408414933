'use client'

import { Checkbox } from '@/components/molecules'
import ErrorModal from '@/components/molecules/Modal/ErrorModal'
import { PAYMENT_STATES } from '@/utils/constants'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import usePaymentHandler from '../../../hooks/usePaymentHandler'
import PaypalAdvancedCheckout from '../AdvancedCheckout'

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const privacyPolicyDisclaimer =
  'Per proseguire, è necessario accettare la privacy policy e i termini e condizioni'

const PaymentMethods = ({
  name,
  isHidden = false,
  disableQueryState = false,
  config,
  uiOptions = {},
  ...rest
}) => {
  const [isDelayComplete, setIsDelayComplete] = useState(false)
  const { showPrivacyAndTerms } = uiOptions
  const { getValues, watch, setValue } = useFormContext()
  const {
    token,
    paymentOptions,
    paypalInitialOptions,
    paypalClientId,
    approvalLink,
    setApprovalLink,
    handleOnCreateOrder: _handleOnCreateOrder,
    handleOnCreateAlternativeOrder: _handleOnCreateAlternativeOrder,
    handleOnApprove: _handleOnApprove,
    handleOnError: _handleOnError,
    paymentState,
    setPaymentState,
    modalErrorIsOpen,
    setModalErrorIsOpen,
  } = usePaymentHandler({
    dataToSend: { ...getValues() },
  })
  const excludedPaymentMethods = [paymentOptions?.['disable-funding']] ?? []

  const [paymentApiResponse, updatePaymentApiResponse] = useState('')

  const [privacyAndTermsValues, setPrivacyAndTermsValues] = useState({
    privacy: Boolean(watch('privacy') || false),
    termsAndConditions: Boolean(watch('termsAndConditions') || false),
    newsletter: Boolean(watch('newsletter') || false),
  })
  const isPrivacyChecked =
    privacyAndTermsValues.privacy === true ||
    privacyAndTermsValues.privacy === 'true'

  const isLoading =
    !token || !paypalClientId || !paymentOptions || !isDelayComplete

  const notifyOnPrivacyUnchecked = () => {
    toast.warn(
      "Devi prima accettare l'informativa sul trattamento dei dati personali",
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      },
      { delay: 500 }
    )
  }

  const updateChosenPaymentMethodFormValue = (paymentMethod) =>
    setValue(name, paymentMethod)

  const handleOnCreateOrder = async (data) => {
    const { privacy } = privacyAndTermsValues
    if (privacy !== true) {
      notifyOnPrivacyUnchecked()
      return
    }
    const { paymentSource } = data
    if (!paymentSource) return
    const orderCreationResponse = await _handleOnCreateOrder(data)
    updateChosenPaymentMethodFormValue(data.paymentSource)
    updatePaymentApiResponse(orderCreationResponse)
    const { orderID } = orderCreationResponse
    return orderID
  }

  const handleAlternativeCreateOrder = async (paymentSource) => {
    if (isPrivacyChecked !== true) {
      notifyOnPrivacyUnchecked()
      return
    }
    if (!paymentSource) return
    updateChosenPaymentMethodFormValue(paymentSource)
    const innerApproveResponse =
      await _handleOnCreateAlternativeOrder(paymentSource)
    return innerApproveResponse
  }

  const handleOnApprove = async (data) => {
    if (isPrivacyChecked !== true) {
      notifyOnPrivacyUnchecked()
      return
    }
    const innerApproveResponse = await _handleOnApprove({
      ...data,
      ...paymentApiResponse,
    })
    return innerApproveResponse
  }

  const handleOnError = async (data) => {
    if (isPrivacyChecked !== true) {
      notifyOnPrivacyUnchecked()
      return
    }
    const innerErrorResponse = await _handleOnError(data)
    return innerErrorResponse
  }

  const handleSelectCreditCard = (paymentMethod) =>
    updateChosenPaymentMethodFormValue(paymentMethod)

  const modalCopy = {
    title:
      modalErrorIsOpen === PAYMENT_STATES.PAYMENT_ERROR
        ? 'Ops qualcosa è andato storto'
        : '',
    body:
      modalErrorIsOpen === PAYMENT_STATES.PAYMENT_ERROR
        ? 'Si è verificato un errore durante il pagamento, ritenta.'
        : 'Stiamo elaborando il pagamento, attendi...',
    cta: 'Chiudi',
  }

  useEffect(() => {
    const initDelay = async () => {
      await delay(2000)
      setIsDelayComplete(true)
    }
    initDelay()
  }, [])

  return (
    <div className='payment-methods'>
      <ErrorModal
        isOpen={
          modalErrorIsOpen === PAYMENT_STATES.PAYMENT_ERROR ||
          modalErrorIsOpen === PAYMENT_STATES.WAITING_FOR_PAYMENT
        }
        isErrror={modalErrorIsOpen === PAYMENT_STATES.PAYMENT_ERROR}
        isLoading={modalErrorIsOpen === PAYMENT_STATES.WAITING_FOR_PAYMENT}
        onClose={() => {
          setModalErrorIsOpen(PAYMENT_STATES.END_WAITING_FOR_PAYMENT)
          const oldMethod = paymentState.method
          setPaymentState({
            ...paymentState,
            status:
              oldMethod === 'creditcard'
                ? PAYMENT_STATES.SELECTED_CARD
                : PAYMENT_STATES.IDLE,
            method: '',
          })
        }}
        copy={modalCopy}
      />

      {showPrivacyAndTerms ? (
        <>
          <Checkbox
            name='privacy'
            useFormContextIfAvailable={true}
            onChange={(e) =>
              setPrivacyAndTermsValues((prev) => ({
                ...prev,
                privacy: !prev.privacy,
              }))
            }
          />
          <Checkbox
            name='termsAndConditions'
            useFormContextIfAvailable={true}
            onChange={(e) =>
              setPrivacyAndTermsValues((prev) => ({
                ...prev,
                termsAndConditions: !prev.termsAndConditions,
              }))
            }
          />
          <Checkbox
            name='newsletter'
            useFormContextIfAvailable={true}
            onChange={(e) =>
              setPrivacyAndTermsValues((prev) => ({
                ...prev,
                newsletter: !prev.newsletter,
              }))
            }
          />
        </>
      ) : null}
      <PaypalAdvancedCheckout
        paypalInitialOptions={paypalInitialOptions}
        excludedPaymentMethods={excludedPaymentMethods}
        onCreateOrder={handleOnCreateOrder}
        onAlternativeCreateOrder={handleAlternativeCreateOrder}
        onApprove={handleOnApprove}
        onError={handleOnError}
        isLoading={isLoading}
        isDisabled={isPrivacyChecked === true ? false : true}
        paymentState={paymentState}
        setPaymentState={setPaymentState}
        onSelectCreditCard={handleSelectCreditCard}
      />
    </div>
  )
}
// const [chosenPaymentMethod, setChosenPaymentMethod] = useQueryState(name, {
//   throttleMs: disableQueryState ? +Infinity : 100,
// })

export default PaymentMethods
