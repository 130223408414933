/* ---------------------------------
Types (sort of)

Please keep in mind this is just an
attempt to keep things consistent.

It is very much a work in progress,
and mismatches in the PropTypes
attached to components are expected.
--------------------------------- */

import PropTypes from 'prop-types'

export const ImageType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  alternativeText: PropTypes.string,
  caption: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  hash: PropTypes.string,
  ext: PropTypes.string,
  mime: PropTypes.string,
  size: PropTypes.number,
  url: PropTypes.string.isRequired,
  previewUrl: PropTypes.string,
  provider: PropTypes.string,
  provider_metadata: PropTypes.any,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
})

export const VideoType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  poster: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
})

export const CTAType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
})

export const TestimonialCardType = PropTypes.shape({
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      text: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }),
})

export const ProgressCardType = PropTypes.shape({
  figures: PropTypes.shape({
    part: PropTypes.number.isRequired,
    whole: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
  }).isRequired,
  testimonials: PropTypes.node,
  ctaMessage: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})

export const SlideType = PropTypes.shape({
  type: PropTypes.oneOf(['video', 'image']).isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  cta: CTAType,
  image: ImageType,
  video: VideoType,
  testimonialCard: TestimonialCardType,
})
