const ValidCheck = () => {
  return (
    <svg
      className='valid-check'
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='5'
        cy='5'
        r='5'
        fill='#4ECA42'
      />
      <path
        d='M2.5 4.5L4.5 6.5L7.5 3.5'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ValidCheck
