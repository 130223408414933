import Modal from '@/components/molecules/Modal'
import LetterPreviewBuilder from '@/components/molecules/PreviewBuilder/LetterInMemory'

const InMemoryLetterPreviewModal = ({ isOpen, onClose, onOpen }) => {
  return (
    <>
      <button
        className={`open-preview-button`}
        onClick={onOpen}
      >
        Anteprima
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M22.25 12C22.25 12.3851 22.0255 12.9749 21.4649 13.6989C20.9206 14.4018 20.123 15.1476 19.1424 15.8306C17.1742 17.2016 14.571 18.25 12 18.25C9.42901 18.25 6.82584 17.2016 4.8576 15.8306C3.87696 15.1476 3.07941 14.4018 2.53508 13.6989C1.97445 12.9749 1.75 12.3851 1.75 12C1.75 11.6149 1.97445 11.0251 2.53508 10.3011C3.07941 9.59823 3.87696 8.85242 4.8576 8.16935C6.82584 6.79838 9.42901 5.75 12 5.75C14.571 5.75 17.1742 6.79838 19.1424 8.16935C20.123 8.85242 20.9206 9.59823 21.4649 10.3011C22.0255 11.0251 22.25 11.6149 22.25 12Z'
            stroke='#FAFAFA'
            stroke-width='1.5'
          />
          <circle
            cx='12'
            cy='12'
            r='4.25'
            stroke='#FAFAFA'
            stroke-width='1.5'
          />
        </svg>
      </button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        classNames={{
          modalOverlay: 'modal-overlay preview-modal',
          modalContent: 'modal-content preview-modal',
          closeButton: 'close-button preview-modal',
        }}
      >
        <LetterPreviewBuilder useFormContextIfAvailable={true} />
      </Modal>
    </>
  )
}

export default InMemoryLetterPreviewModal
