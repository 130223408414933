/* ---------------------------------
StoriesSection
--------------------------------- */

import {
  Anchor,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { ImageCard, Slider, VideoCard } from 'components/molecules'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SlideType } from '@/types'

export default function StoriesSection({ data }) {
  const { attributes } = data ?? {}
  const {
    openingTitle,
    title,
    paragraph,
    slides,
    bg: sectionBg,
    textAlign,
    paragraphPosition,
  } = attributes ?? {}

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'StoriesSection',
        paragraphPosition === 'bottom' && 'StoriesSection--layout_parag_bottom',
        `StoriesSection--text-align-${textAlign || 'center'}`
      )}
    >
      <div className='StoriesSection__text-content'>
        <div className='StoriesSection__text-content__titles'>
          {openingTitle ? (
            <OpeningTitle mbXS>{openingTitle}</OpeningTitle>
          ) : null}

          {title ? (
            <Title
              mt0
              mbM
              level={2}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}
        </div>

        {paragraph ? (
          <Paragraph className={'StoriesSection__text-content__body'}>
            {paragraph}
          </Paragraph>
        ) : null}
      </div>

      {slides?.length ? (
        slides.length === 1 ? (
          // TODO once mocks are removed, each slide
          // would construct an ImageCard or VideoCard
          // depending on the type.
          <ImageCard
            className={'StoriesSection__image-card'}
            mbM
            title={'Nome, NN anni'}
            body={'AIRC mi ha donato nuova luce quando ne ho avuto più bisogno'}
            cta={<Anchor href={'/dona'}>Call to action</Anchor>}
            image={slides[0].image}
          />
        ) : (
          <Slider
            slides={slides.map((slide, i) => {
              const { type, title, body, cta, image, video } = slide

              if (type === 'image' && !!image) {
                return (
                  <ImageCard
                    key={'ImageCard__' + i}
                    className={'StoriesSection__image-card'}
                    mbM
                    title={title ?? ''}
                    body={body ?? ''}
                    cta={
                      <Anchor href={cta?.url ?? '/'}>{cta.label ?? ''}</Anchor>
                    }
                    image={image}
                  />
                )
              }

              if (type === 'video' && !!video) {
                return (
                  <VideoCard
                    key={'VideoCard__' + i}
                    mbM
                    video={{
                      src: video.url,
                      poster: video.poster,
                    }}
                  />
                )
              }
            })}
          />
        )
      ) : null}
    </Section>
  )
}

StoriesSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols', 'single_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      paragraphPosition: PropTypes.oneOf(['right', 'bottom']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      slides: PropTypes.arrayOf(SlideType),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
