'use client'

import { PAYMENT_STATES } from '@/utils/constants'
import {
  destroySDKScript,
  getScriptID,
  PayPalButtons,
  PayPalHostedField,
  PayPalHostedFieldsProvider,
  PayPalScriptProvider,
  usePayPalHostedFields,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'
import { ErrorBoundary } from 'components/molecules'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IoArrowBackOutline, IoCardOutline } from 'react-icons/io5'
import { toast } from 'react-toastify'

function PaypalAdvancedCheckout({
  paypalInitialOptions,
  onCreateOrder = () => {},
  onAlternativeCreateOrder = () => {},
  onApprove = () => {},
  onError = () => {},
  offlineMethods = false,
  excludedPaymentMethods = [],
  isLoading = false,
  isDisabled = false,
  paymentState,
  setPaymentState,
  onSelectCreditCard,
}) {
  const [isCardFormInvalid, setIsCardFormInvalid] = useState(false)
  const [cardIsPaying, setCardIsPaying] = useState(false)
  const [isCardCreditSelected, setIsCardCreditSelected] = useState(false)

  const notifyOnPrivacyUnchecked = () => {
    toast.warn(
      "Devi prima accettare l'informativa sul trattamento dei dati personali",
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      },
      { delay: 500 }
    )
  }

  const handleIsDisabled = (e) => {
    if (isDisabled) {
      notifyOnPrivacyUnchecked()
      return
    }
    return e
  }

  const handlePayPalCreateOrder = async (data) => {
    return await onCreateOrder(data)
  }

  const handleAlternativePayment = async (paymentSource) => {
    return await onAlternativeCreateOrder(paymentSource)
  }

  const handleApprove = async (data) => {
    return await onApprove(data)
  }

  const handleError = async (error) => {
    return await onError(error)
  }

  const handleSelectCreditCard = (value) => {
    if (isDisabled) {
      notifyOnPrivacyUnchecked()
      setIsCardCreditSelected(false)
      return
    }
    setIsCardCreditSelected(value)
    onSelectCreditCard(value === true ? 'creditcard' : null)
    setPaymentState({
      state: PAYMENT_STATES.SELECTED_CARD,
      method: 'creditcard',
      info: {},
    })
  }

  const handleCancel = () => {
    handleIsDisabled()
  }

  const memoizedRenderCreditCardButton = useMemo(() => {
    return (
      <PayPalHostedFieldsProvider
        createOrder={() =>
          handlePayPalCreateOrder({ paymentSource: 'creditcard' })
        }
        styles={{
          input: {
            'font-size': '1rem',
            'font-weight': '700',
            padding: '1rem',
            color: '#121212',
          },
          '.invalid': {
            'border-color': 'red',
          },
        }}
      >
        {/* Card Number Field */}
        <div id='card-number-field'>
          {/* <label htmlFor='card-number'>Card Number</label> */}
          <PayPalHostedField
            id='card-number'
            hostedFieldType='number'
            options={{
              selector: '#card-number',
              placeholder: 'Numero carta ',
            }}
          />
        </div>

        {/* CVV Field */}
        <div id='cvv-field'>
          {/* <label htmlFor='cvv'>CVV</label> */}
          <PayPalHostedField
            id='cvv'
            hostedFieldType='cvv'
            options={{
              selector: '#cvv',
              placeholder: 'CVV',
              maskInput: true,
            }}
          />
        </div>

        {/* Expiration Date Field */}
        <div id='expiration-date-field'>
          {/* <label htmlFor='expiration-date'>Expiration Date</label> */}
          <PayPalHostedField
            id='expiration-date'
            hostedFieldType='expirationDate'
            options={{
              selector: '#expiration-date',
              placeholder: 'MM/AA',
            }}
          />
        </div>

        <SubmitButton
          isFormInvalid={isCardFormInvalid}
          onValidation={setIsCardFormInvalid}
          onConfirmPayment={handleApprove}
          onErrorPayment={handleError}
          onPaying={setCardIsPaying}
        />
      </PayPalHostedFieldsProvider>
    )
  }, [isDisabled])

  if (cardIsPaying && paymentState.state === PAYMENT_STATES.SELECTED_CARD) {
    setPaymentState({
      state: PAYMENT_STATES.WAITING_FOR_PAYMENT,
      method: 'creditcard',
      info: {},
    })
  }
  if (cardIsPaying && paymentState.state === PAYMENT_STATES.IDLE) {
    setCardIsPaying(false)
  }

  useEffect(() => {
    return () => {
      destroySDKScript(getScriptID(paypalInitialOptions))
    }
  }, [paypalInitialOptions])

  if (
    !paypalInitialOptions?.['data-client-token'] ||
    !paypalInitialOptions?.['client-id'] ||
    isLoading
  ) {
    return (
      <div className='advanced-checkout-main-wrapper'>
        <div className='skeleton-loader'></div>
        <div className='skeleton-loader'></div>
        <div className='skeleton-loader'></div>
        <div className='skeleton-loader'></div>
      </div>
    )
  }
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <div
        className={`advanced-checkout-main-wrapper ${
          isDisabled ? 'disabled' : ''
        }  `}
        tabIndex='-1'
      >
        <PayPalScriptProvider
          deferLoading={true}
          options={paypalInitialOptions}
        >
          <AdvancedCheckout
            isCardCreditSelected={isCardCreditSelected}
            paypalInitialOptions={paypalInitialOptions}
          >
            <PayPalPaymentMethods
              handlePayPalCreateOrder={handlePayPalCreateOrder}
              handleApprove={handleApprove}
              handleError={handleError}
              handleCancel={handleCancel}
              handleIsDisabled={handleIsDisabled}
              excludedPaymentMethods={excludedPaymentMethods}
              renderCreditCardButton={memoizedRenderCreditCardButton}
              onCreditCardSelected={handleSelectCreditCard}
              isCardCreditSelected={isCardCreditSelected}
              isDisabled={isDisabled}
              isPaying={cardIsPaying}
            />

            <AlternativePaymentMethods
              handleAlternativePayment={handleAlternativePayment}
              excludedPaymentMethods={excludedPaymentMethods}
              offlineMethods={offlineMethods}
            />
          </AdvancedCheckout>
        </PayPalScriptProvider>
      </div>
    </ErrorBoundary>
  )
}

function AdvancedCheckout({
  children,
  isCardCreditSelected,
  paypalInitialOptions,
}) {
  const [{ isPending, isInitial, isResolved, isRejected }, dispatch] =
    usePayPalScriptReducer()

  const initializeScript = () => {
    dispatch({
      type: 'setLoadingStatus',
      value: 'initial',
    })
  }

  const destroyScript = useCallback(() => {
    destroySDKScript(getScriptID(paypalInitialOptions))
  }, [paypalInitialOptions])

  const destroyAndInitializeScript = async () => {
    initializeScript()
    return
  }

  useEffect(() => {
    if (isInitial && !isPending && !isResolved) {
      dispatch({
        type: 'setLoadingStatus',
        value: 'pending',
      })
    }
    if (isPending || isResolved) {
      return
    }

    return () => {
      destroyScript()
    }
  }, [destroyScript, dispatch, isInitial, isPending, isResolved])
  return (
    <div
      className={`advanced-checkout-child-wrapper ${
        isPending ? 'spinner' : ''
      } ${isCardCreditSelected ? 'credit-card-selected' : ''}`}
      tabIndex='-1'
    >
      {children}
    </div>
  )
}

function PayPalPaymentMethods({
  handlePayPalCreateOrder,
  handleApprove,
  handleError,
  handleCancel,
  handleIsDisabled,
  excludedPaymentMethods,
  renderCreditCardButton,
  onCreditCardSelected,
  isCardCreditSelected,
  isDisabled = false,
  isPaying,
}) {
  const containerOnClick = isCardCreditSelected
    ? (e) => {
        e.preventDefault()
      }
    : (e) => onCreditCardSelected(true)
  const backButtonOnClick =
    isCardCreditSelected && !isPaying
      ? () => {
          onCreditCardSelected(false)
        }
      : (e) => {
          e.preventDefault()
        }

  return (
    <>
      {!excludedPaymentMethods.includes('creditcard-activation') && (
        <div
          className={`${
            isCardCreditSelected ? 'selected' : ''
          } payment-method credit-card-button`}
          onClick={containerOnClick}
          tabIndex='0'
        >
          {!isCardCreditSelected ? (
            <div
              className={'credit-card-button-text'}
              tabIndex='-1'
            >
              <IoCardOutline />
              {'Carta *1234'}
            </div>
          ) : null}
          <button
            className={` credit-card-back-button ${
              isCardCreditSelected ? 'selected' : ''
            } `}
            tabIndex='0'
            onClick={backButtonOnClick}
          >
            <IoArrowBackOutline />
          </button>
          {renderCreditCardButton}
        </div>
      )}

      {!excludedPaymentMethods.includes('paypal-activation') && (
        <PayPalButtons
          className='payment-method paypal-button'
          createOrder={() =>
            handlePayPalCreateOrder({
              paymentSource: 'paypal',
            })
          }
          onApprove={handleApprove}
          onError={handleError}
          onCancel={handleCancel}
          disabled={isDisabled}
          onClick={handleIsDisabled}
          forceReRender={[isDisabled]}
          fundingSource={'paypal'}
          style={{
            shape: 'sharp',
            disableMaxHeight: true,
            disableMaxWidth: true,
          }}
          tabIndex='0'
        />
      )}
    </>
  )
}

function AlternativePaymentMethods({
  handleAlternativePayment,
  excludedPaymentMethods,
  offlineMethods,
}) {
  return (
    <>
      {!excludedPaymentMethods.includes('postepay-activation') && (
        <div
          tabIndex='0'
          role='button'
          aria-label='Paga con: PostePay'
          className='payment-method poste-pay-button'
          onClick={() => handleAlternativePayment('postepay')}
        ></div>
      )}

      {!excludedPaymentMethods.includes('satispay-activation') && (
        <div
          tabIndex='0'
          role='button'
          aria-label='Paga con: Satispay'
          className='payment-method satispay-button'
          onClick={() => handleAlternativePayment('satispay')}
        ></div>
      )}

      {offlineMethods && (
        <>
          <div className='payment-method'>
            <div onClick={() => handleAlternativePayment('transfer')}>
              Bank Transfer
            </div>
          </div>
          <div className='payment-method'>
            <div onClick={() => handleAlternativePayment('account')}>
              Postal Account
            </div>
          </div>
        </>
      )}
    </>
  )
}
function SubmitButton({
  isFormInvalid,
  onValidation,
  onConfirmPayment,
  onErrorPayment,
  onPaying,
}) {
  const hostedFields = usePayPalHostedFields()
  const [isPaying, setIsPaying] = useState(false)

  const handleClick = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!hostedFields && !hostedFields?.cardFields) return false

    const isInvalid = Object.values(
      hostedFields.cardFields.getState().fields
    ).some((field) => !field.isValid)
    if (isInvalid) return false

    setIsPaying(true)
    onPaying(true)
    try {
      const submitData = await hostedFields.cardFields.submit()
      onConfirmPayment(submitData)
    } catch (error) {
      onErrorPayment(error)
      setIsPaying(false)
      onPaying(false)
    }
  }
  return (
    <button
      className={'dynamic-form_button-confirm pay-credit-card'}
      onClick={handleClick}
    >
      {'Procedi al pagamento'}
    </button>
  )
}

export default PaypalAdvancedCheckout
