/* ---------------------------------
ProgressSection
--------------------------------- */

import {
  Button,
  Image,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import { HighlightCard, TextCard } from 'components/molecules'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRouter } from 'next/navigation'
import { useMediaQuery } from '@uidotdev/usehooks'

export default function ProgressSection({ data }) {
  const { attributes } = data ?? {}
  const {
    openingTitle,
    title,
    paragraph,
    image,
    ctaButton,
    highlightCard,
    layout,
    bg: sectionBg,
    textAlign,
  } = attributes ?? {}

  const router = useRouter()
  const isLG = useMediaQuery('only screen and (min-width: 1024px)')

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'ProgressSection',
        layout === 'invert_cols' && 'ProgressSection--layout_invert_cols',
        `ProgressSection--text-align-${textAlign || 'center'}`
      )}
    >
      <div className='ProgressSection__grid'>
        <div className='ProgressSection__grid__col-1'>
          {openingTitle ? (
            <OpeningTitle mbS>{openingTitle}</OpeningTitle>
          ) : null}

          {title ? (
            <Title
              mt0
              mbS
              level={2}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}

          {paragraph ? <Paragraph mbL>{paragraph}</Paragraph> : null}

          {highlightCard ? (
            <HighlightCard
              className={'ProgressSection__highlightCard'}
              mbM={!isLG || (isLG && !!ctaButton)}
              title={highlightCard.title}
            >
              {highlightCard.body}
            </HighlightCard>
          ) : null}

          {ctaButton ? (
            <Button
              className={'EditorialSection__cta-button'}
              variant={'cta'}
              // TODO what if it's a fully qualified URL
              onClick={() => router.push(ctaButton.url)}
            >
              {ctaButton.label}
            </Button>
          ) : null}
        </div>

        {/* TODO move data to mock */}
        <div className='ProgressSection__grid__col-2'>
          <TextCard
            mbXS
            title={'2024'}
          >
            Nel corso dell’ultimo anno abbiamo scoperto come le cellule tumorali
            reagiscano ad x
          </TextCard>

          <TextCard
            mbXS
            title={'2023'}
          >
            Il tumore al seno è diventato più facilmente individuabile grazie a
            y
          </TextCard>

          <TextCard
            mbL
            title={'2022'}
          >
            Siamo riusciti ad allungare l’aspettativa di vita dei pazienti con
            tumore al pancreas
          </TextCard>

          {image ? (
            <Image
              className={'ProgressSection__image'}
              src={image.url}
              width={image.width}
              height={image.height}
              sizes='(min-width: 1440px) 600px, (min-width: 1024px) 50vw, 100vw'
              alt={image.alternativeText}
            />
          ) : null}
        </div>
      </div>
    </Section>
  )
}

ProgressSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      highlightCard: PropTypes.shape({
        id: PropTypes.number,
        attributes: PropTypes.shape({
          title: PropTypes.string,
          body: PropTypes.string,
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
          publishedAt: PropTypes.string,
        }),
      }),
      // TODO other structured data types
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
      ctaButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
