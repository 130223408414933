'use client'

/* ---------------------------------
Slider
--------------------------------- */

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { SliderNavigation } from 'components/molecules/Slider/SliderNavigation'

export default function Slider({ slides, children, className, ...styleProps }) {
  return (
    <div
      className={classNames('Slider', className, extractStyleProps(styleProps))}
    >
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          nextEl: '.Slider__controls__navigation__button--next',
          prevEl: '.Slider__controls__navigation__button--prev',
        }}
        pagination={{
          clickable: true,
          el: '.Slider__controls__pagination',
          bulletClass: 'Slider__controls__pagination__step',
          bulletActiveClass: 'Slider__controls__pagination__step--active',
        }}
      >
        {slides?.map?.((slide, i) => (
          <SwiperSlide key={`Slider__slide__${i}`}>{slide}</SwiperSlide>
        ))}

        {children ? <div className={'Slider__children'}>{children}</div> : null}

        <div className='Slider__controls'>
          {/*
            Swiper will generate the nodes for us;
            the classes are targeted in src/styles/components/_slider.scss:22
          */}
          <div className='Slider__controls__pagination' />

          <SliderNavigation />
        </div>
      </Swiper>
    </div>
  )
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.any).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  styleProps: PropTypes.object,
}
