/* ---------------------------------
ImageCard
--------------------------------- */

import { Card } from 'components/atoms'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import PropTypes from 'prop-types'
import Image from 'next/image'

export default function ImageCard({
  title,
  body,
  cta,
  image,
  className,
  ...styleProps
}) {
  const { alternativeText, url } = image

  return (
    <Card
      variant={'bare'}
      className={classNames(
        'ImageCard',
        className,
        extractStyleProps(styleProps)
      )}
    >
      <div className='ImageCard__container'>
        <Image
          fill
          src={url ?? ''}
          alt={alternativeText || 'Immagine di sfondo della card'}
          className='ImageCard__image'
          sizes='(min-width: 1440px) 1440px, (min-width: 1024px) 960px, 100vw'
          priority={false}
        />
        <div className='ImageCard__overlay'>
          <div className='ImageCard__content'>
            {title ? (
              <span className={'ImageCard__content__title'}>{title}</span>
            ) : null}

            {body ? <p className={'ImageCard__content__body'}>{body}</p> : null}

            {cta ? (
              <div className={'ImageCard__content__cta'}>{cta}</div>
            ) : null}
          </div>
        </div>
      </div>
    </Card>
  )
}

ImageCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  cta: PropTypes.node,
  image: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    alternativeText: PropTypes.string,
    caption: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    hash: PropTypes.string,
    ext: PropTypes.string,
    mime: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string.isRequired,
    previewUrl: PropTypes.string,
    provider: PropTypes.string,
    provider_metadata: PropTypes.any,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  styleProps: PropTypes.object,
}
