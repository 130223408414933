'use client'

import { Fragment, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

const RadioGroup = ({
  label = 'Ricorrenza della donazione',
  classNames = {
    radioGroup: 'radio-group',
    radioOption: 'radio-option',
    radioOptionLabel: 'radio-option_label',
    radioOptionSubLabel: 'radio-option_sublabel',
  },
  name = 'donation-period',
  defaultValue: _defaultValue = '',
  radioOptions: _radioOptions = [
    {
      value: 'mensile',
      label: 'Mensile',
      subLabel: '',
      name: 'donation-period',
    },
    {
      value: 'semestrale',
      label: 'Semestrale',
      subLabel: '',
      name: 'donation-period',
    },
    {
      value: 'custom',
      label: 'Annuale',
      subLabel: '',
      name: 'donation-period',
    },
  ],
  radioOptionsByField = false,
  isHidden = false,
  disableQueryState = false,
  value = '',
  useFormContextIfAvailable = false,
  config,
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
  } = useFormContext()

  const [radioOptions, defaultValue] = useFormContextIfAvailable
    ? getRadioOptions(
        _radioOptions,
        radioOptionsByField,
        getValues(),
        _defaultValue,
        value
      )
    : [_radioOptions, _defaultValue]
  const isEditField = name.includes('edit')
  const currentFormValue = getValues(name) || value
  const [selectedRadioValue, setSelectedRadioValue] = useState(
    useFormContextIfAvailable && !isEditField ? currentFormValue : defaultValue
  )
  const [radioInputValue, updateRadioInputValue] = useState(
    selectedRadioValue.startsWith('custom-number-')
      ? selectedRadioValue.split('-')[2]
      : 1
  )

  const hasCustomOptionSelected =
    selectedRadioValue.startsWith('custom') && radioInputValue
  const customOptionType = 'number'

  const currentFormSelection = watch(name) ?? selectedRadioValue
  const { onChange, ref } = register(name)

  const isRadioButtonChecked = (value, index = null) => {
    if (!value.startsWith('custom-number')) {
      const currentRadioOptionsHasValue = radioOptions.some(
        (option) => option.value === value
      )
      const currentRadioOptionsHasSelectedOrCurrentFormValue =
        radioOptions.some(
          (option) =>
            option.value === selectedRadioValue ||
            option.value === currentFormValue
        )
      if (
        !currentRadioOptionsHasSelectedOrCurrentFormValue &&
        currentRadioOptionsHasValue
      ) {
        return index === 0
      }
      if (
        selectedRadioValue === defaultValue ||
        currentFormValue === defaultValue
      ) {
        return index === 0
      }
    }
    const radioValue = selectedRadioValue.startsWith(value)
    const formValue = currentFormSelection.startsWith(value)
    return radioValue || formValue
  }

  const addCustomValue = (value) => {
    if (value.startsWith('custom-number')) {
      if (selectedRadioValue.startsWith('custom-number-')) {
        return selectedRadioValue
      }
      if (value === 'custom-number') {
        return `${value}-${radioInputValue}`
      }
    }
    return value
  }

  const handleChange = (e) => {
    const value = e.target.value
    onChange(e)
    setSelectedRadioValue(value)
    if (hasCustomOptionSelected) {
      updateRadioInputValue(1)
    }
  }

  const handleKeyDown = (value) => (e) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault()
      handleChange({
        target: {
          value: addCustomValue(value),
        },
      })
      setValue(name, addCustomValue(value))
    }
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      e.preventDefault()
      const labels = Array.from(
        e.currentTarget.parentNode.querySelectorAll('label[role="radio"]')
      )
      const index = labels.findIndex((label) => label === e.currentTarget)
      const prevIndex = (index - 1 + labels.length) % labels.length
      labels[prevIndex].focus()
    }
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      e.preventDefault()
      const labels = Array.from(
        e.currentTarget.parentNode.querySelectorAll('label[role="radio"]')
      )
      const index = labels.findIndex((label) => label === e.currentTarget)
      const nextIndex = (index + 1) % labels.length
      labels[nextIndex].focus()
    }
  }

  useEffect(() => {
    const configChecks = Object.keys(config)
    if (selectedRadioValue && configChecks.includes('min')) {
      const _currentValue = selectedRadioValue.startsWith('custom-number-')
        ? Number(selectedRadioValue.split('-')[2])
        : Number(selectedRadioValue)
      if (_currentValue < config.min.value) {
        setError(name, {
          type: 'custom',
          message: `Il valore minimo è ${config.min.value} €`,
        })
      } else {
        clearErrors(name)
      }
    }
  }, [selectedRadioValue, radioInputValue])

  if (radioOptions.length === 0) {
    return null
  }

  return (
    <div
      id={name}
      role='radiogroup'
      aria-labelledby={`${name}-label`}
      className={classNames.radioGroup}
      data-options={radioOptions.length}
      data-variant={name}
      data-current-option={selectedRadioValue.split('-')[0]}
    >
      {radioOptions.map(({ value, label, subLabel }, index) => (
        <Fragment key={value}>
          <label
            id={`${value}-label`}
            key={index}
            tabIndex={index === 0 ? 0 : -1}
            className={`${classNames.radioOption} ${
              selectedRadioValue.startsWith('custom') &&
              value.startsWith('custom')
                ? 'custom'
                : ''
            }`}
            onKeyDown={handleKeyDown(value)}
          >
            <input
              {...register(name)}
              type={isHidden ? 'hidden' : 'radio'}
              aria-labelledby={`${value}-label`}
              name={name}
              value={String(addCustomValue(value))}
              checked={isRadioButtonChecked(value, index)}
              onChange={handleChange}
            />
            <span className={classNames.radioOptionLabel}>{label}</span>
            {subLabel && (
              <span className={classNames.radioOptionSubLabel}>{subLabel}</span>
            )}
          </label>
          {isRadioButtonChecked(value) && value.includes('custom-number') && (
            <div className='radio-option_custom-wrapper'>
              <input
                className={`radio-option_custom-radio ${
                  radioInputValue || selectedRadioValue.startsWith('custom')
                    ? 'active'
                    : ''
                }`}
                autoFocus
                type={isHidden ? 'hidden' : customOptionType}
                // type={customOptionType}
                pattern='[0-9]*'
                min={1}
                step={0.5}
                value={radioInputValue}
                onChange={(e) => {
                  const isValid =
                    !isNaN(e.target.value) || e.target.value === ''
                  if (!isValid) {
                    return
                  }
                  const value = Number(e.target.value)
                  setValue(name, `custom-number-${value}`)
                  setSelectedRadioValue(`custom-number-${value}`)
                  updateRadioInputValue(value)
                }}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    const _value = e.target.value
                    const isValid =
                      !isNaN(_value) && _value > 0 && value !== null
                    if (!isValid) return
                    setValue(name, `custom-number-${_value}`)
                    setSelectedRadioValue(`custom-number-${_value}`)
                    updateRadioInputValue(_value)
                  }
                }}
              />
              <span
                className={`radio-option_euro ${
                  radioInputValue || selectedRadioValue.startsWith('custom')
                    ? 'active'
                    : ''
                }`}
              >
                €
              </span>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

function getRadioOptions(
  radioOptions,
  radioOptionsByField,
  formFields,
  defaultValue,
  value
) {
  const hasCustomValue =
    value.startsWith('custom-number-') ||
    defaultValue.startsWith('custom-number-') ||
    false

  if (radioOptionsByField === false) {
    return [radioOptions, value || defaultValue]
  }

  const fieldsThatDefineRadioOptions = Object.keys(radioOptionsByField)
  if (fieldsThatDefineRadioOptions.length === 0) {
    return [radioOptions, value || defaultValue]
  }

  if (fieldsThatDefineRadioOptions.length === 1) {
    const field = fieldsThatDefineRadioOptions.at(0)
    const fieldOptions = radioOptionsByField[field]
    const fieldValue = formFields[field]
    if (!fieldValue) {
      return [radioOptions, value || defualtValue]
    }
    const firstOption = fieldOptions[fieldValue].at(0)
    const newDefaultValue =
      defaultValue !== firstOption.value && !hasCustomValue
        ? firstOption.value
        : defaultValue
    return [fieldOptions[fieldValue], newDefaultValue]
  }
  if (fieldsThatDefineRadioOptions.length > 1) {
    console.error(
      'Only one field can define radio options. Must implemnt new logic'
    )
  }
  return [radioOptions, value || defualtValue]
}

export default RadioGroup
