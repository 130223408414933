/* ---------------------------------
Header
--------------------------------- */

import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import { Button, Container } from 'components/atoms'

export default function Header({
  className,
  containerStyleProps,
  ...styleProps
}) {
  return (
    <header
      className={classNames('Header', className, extractStyleProps(styleProps))}
    >
      <Container
        className={classNames(
          'Header__wrapper',
          extractStyleProps(containerStyleProps)
        )}
      >
        <div className='Header__logo'>
          <img
            src='/assets/image/AIRC-logo.svg'
            alt='Logo di AIRC'
          />
        </div>

        <div className='Header__CTA'>
          <Button
            variant={'cta'}
            onClick={() => 'TODO'}
          >
            Dona
          </Button>
        </div>
      </Container>
    </header>
  )
}
