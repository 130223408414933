/* ---------------------------------
DonorStoriesSection
--------------------------------- */

import {
  Anchor,
  Button,
  OpeningTitle,
  Paragraph,
  Section,
  Title,
} from 'components/atoms'
import {
  ImageCard,
  Slider,
  TestimonialCard,
  VideoCard,
} from 'components/molecules'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRouter } from 'next/navigation'
import { SlideType, TestimonialCardType } from '@/types'
import { useMediaQuery } from '@uidotdev/usehooks'
import React from 'react'

export default function DonorStoriesSection({ data }) {
  const { attributes } = data ?? {}
  const {
    openingTitle,
    title,
    paragraph,
    slides,
    ctaButton,
    testimonialCard,
    layout,
    bg: sectionBg,
    textAlign,
  } = attributes ?? {}

  const router = useRouter()
  const isLG = useMediaQuery('only screen and (min-width: 1024px)')

  return (
    <Section
      bg={sectionBg}
      className={classNames(
        'DonorStoriesSection',
        layout === 'invert_cols' && 'DonorStoriesSection--layout_invert_cols',
        `DonorStoriesSection--text-align-${textAlign || 'center'}`
      )}
    >
      <div className='DonorStoriesSection__grid'>
        <div className='DonorStoriesSection__grid__col-1'>
          {openingTitle ? (
            <OpeningTitle mbXS>{openingTitle}</OpeningTitle>
          ) : null}

          {title ? (
            <Title
              mt0
              mbXS
              level={2}
            >
              {/* TODO sanitize */}
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          ) : null}

          {paragraph ? (
            <Paragraph mbM={!isLG || (isLG && !!ctaButton)}>
              {paragraph}
            </Paragraph>
          ) : null}

          {/*
            NOTE: the fact that a Testimonial Card appears on the
            left side of the layout or the right side (inside a slide)
            should be handled in the BE in such a way that one option
            excludes the other; this way if the stand-alone testimonialCard
            is present, then there will be no testimonialCards inside the slides.
          */}
          {testimonialCard && isLG ? (
            <div style={{ marginBottom: 'var(--Spacing-M)', maxWidth: 500 }}>
              <TestimonialCard
                title={testimonialCard.data?.attributes?.title}
                subtitle={testimonialCard.data?.attributes?.subtitle}
                image={testimonialCard.data?.attributes?.image?.url}
              >
                {testimonialCard.data?.attributes?.text}
              </TestimonialCard>
            </div>
          ) : null}

          {ctaButton ? (
            <Button
              className={'EditorialSection__cta-button'}
              variant={'cta'}
              // TODO what if it's a fully qualified URL
              onClick={() => router.push(ctaButton?.url)}
            >
              {ctaButton?.label}
            </Button>
          ) : null}
        </div>

        <div className='DonorStoriesSection__grid__col-2'>
          {slides?.length ? (
            slides.length === 1 ? (
              // TODO once mocks are removed, each slide
              // would construct an ImageCard or VideoCard
              // depending on the type.
              <>
                <VideoCard
                  mbM
                  video={{
                    src: slides[0].video?.url,
                    poster: slides[0].video?.poster,
                  }}
                />

                {slides[0].testimonialCard ? (
                  <TestimonialCard
                    title={slides[0].testimonialCard.data?.attributes?.title}
                    subtitle={
                      slides[0].testimonialCard.data?.attributes?.subtitle
                    }
                    image={
                      slides[0].testimonialCard.data?.attributes?.image?.url
                    }
                  >
                    {slides[0].testimonialCard.data?.attributes?.text}
                  </TestimonialCard>
                ) : null}
              </>
            ) : (
              <Slider
                slides={slides.map((slide, i) => {
                  const {
                    type,
                    title,
                    body,
                    cta,
                    image,
                    video,
                    testimonialCard,
                  } = slide

                  if (type === 'image' && !!image) {
                    return (
                      <div
                        key={'ImageCard__' + i}
                        style={{ paddingBottom: 'var(--Spacing-M)' }}
                      >
                        <ImageCard
                          className={'DonorStoriesSection__image-card'}
                          mbM
                          title={title ?? ''}
                          body={body ?? ''}
                          cta={
                            <Anchor href={cta?.url ?? '/'}>
                              {cta.label ?? ''}
                            </Anchor>
                          }
                          image={image}
                        />

                        {testimonialCard ? (
                          <TestimonialCard
                            title={testimonialCard.data?.attributes?.title}
                            subtitle={
                              testimonialCard.data?.attributes?.subtitle
                            }
                            image={testimonialCard.data?.attributes?.image?.url}
                          >
                            {testimonialCard.data?.attributes?.text}
                          </TestimonialCard>
                        ) : null}
                      </div>
                    )
                  }

                  if (type === 'video' && !!video) {
                    return (
                      <div
                        key={'VideoCard__' + i}
                        style={{ paddingBottom: 'var(--Spacing-M)' }}
                      >
                        <VideoCard
                          mbM
                          video={{
                            src: video.url,
                            poster: video.poster,
                          }}
                        />

                        {testimonialCard ? (
                          <TestimonialCard
                            title={testimonialCard.data?.attributes?.title}
                            subtitle={
                              testimonialCard.data?.attributes?.subtitle
                            }
                            image={testimonialCard.data?.attributes?.image?.url}
                          >
                            {testimonialCard.data?.attributes?.text}
                          </TestimonialCard>
                        ) : null}
                      </div>
                    )
                  }
                })}
              ></Slider>
            )
          ) : null}
        </div>
      </div>
    </Section>
  )
}

DonorStoriesSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    attributes: PropTypes.shape({
      // this would represent a CMS enumeration
      layout: PropTypes.oneOf(['invert_cols', 'single_col']),
      bg: PropTypes.oneOf([
        'dark',
        'black',
        'blue',
        'dark-blue',
        'dark-green',
        'green',
        'grey-050',
        'grey-100',
        'grey-200',
        'grey-300',
        'grey-500',
        'grey-700',
        'grey-900',
        'rich-blue',
        'white',
      ]),
      textAlign: PropTypes.oneOf(['left', 'center']),
      openingTitle: PropTypes.string,
      title: PropTypes.string,
      paragraph: PropTypes.string,
      ctaButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      testimonialCard: TestimonialCardType,
      slides: PropTypes.arrayOf(SlideType),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }).isRequired,
}
