'use client'

import { useFormContext } from 'react-hook-form'

const mainClass = 'template__letter-in-memory'

const LetterPreviewBuilder = (props) => {
  const { data, useFormContextIfAvailable } = props

  const formContext = useFormContext()
  const formValues = formContext?.getValues()
  const isDigitalFormat = formValues?.donationLetterFormat === 'digital'
  const isSingleDonator = formValues?.donationLetterPartecipants?.length === 0
  const isCompany = formValues?.donatorType === 'company'

  const today = getFormattedDate()

  const formData = {
    sender: {
      type: formValues.donationLetterRecipient || '',
      surname: formValues.donationLetterRecipientName || '',
      ...(!isDigitalFormat
        ? {
            address: formValues.donationLetterRecipientAddress || '',
            number: formValues.donationLetterRecipientAddressNumber || '',
            cap: formValues.donationLetterRecipientPostalCode || '',
            city: formValues.donationLetterRecipientCity || '',
            province: formValues.donationLetterRecipientProvince || '',
          }
        : {}),
    },
    date: getFormattedDate(formValues.donationInMemoryDate) || today,
    memoryOf: formValues.donationInMemoryReceiverName || '',
    ...(isSingleDonator
      ? {
          group: [
            isCompany
              ? formValues.donatorCompanyName
              : `${formValues.donatorFirstName} ${formValues.donatorLastName}`,
          ],
        }
      : {
          group:
            formValues.donationLetterPartecipants?.map((i) => i.value) || [],
        }),
  }

  const width = 1240
  const height = 1754

  const defaultData = {
    sender: {
      type: 'Famiglia',
      surname: 'Rossi',
      address: 'Via Roma',
      number: '5',
      cap: '43127',
      city: 'Parma',
      province: 'PR',
    },
    memoryOf: 'Luca Bianchi',
    group: ['Mario Rossi', 'Maria Bianchi', 'Lucia Verdi'],
    date: today,
  }

  const currentData = useFormContextIfAvailable ? formData : data || defaultData

  const group = currentData.group ? currentData.group.join(', ') : ''

  return (
    <>
      <div className={`${mainClass}`}>
        <div
          className={`${mainClass}__container`}
          data-scale={height / width}
        >
          <p className={`${mainClass}__container__sender`}>
            Gentile {currentData.sender.type}
            <br />
            {startCase(currentData.sender.surname.toLowerCase())}
            <br />
            {currentData.sender.address} {currentData.sender.number}
            <br />
            {currentData.sender.cap} {currentData.sender.city}{' '}
            {currentData.sender.province}
          </p>
          <p className={`${mainClass}__container__date`}>{currentData.date}</p>
          <p className={`${mainClass}__container__paragraph`}>
            Gentile {currentData.sender.type}{' '}
            {startCase(currentData.sender.surname.toLowerCase())},
          </p>
          <p className={`${mainClass}__container__paragraph`}>
            {group} {currentData.group.length > 1 ? 'hanno' : 'ha'} devoluto un
            generoso contributo a sostegno delle attività della nostra
            Fondazione, in memoria di {currentData.memoryOf}.
          </p>
          <br />
          <p className={`${mainClass}__container__paragraph`}>
            Ci uniamo anche noi nel porgerle le nostre più sentite condoglianze.
          </p>
        </div>
      </div>
    </>
  )
}

function getFormattedDate(customDate) {
  const getDateToday = () => {
    const today = new Date()
    return {
      day: today.getDate(),
      month: today.getMonth(),
      year: today.getFullYear(),
    }
  }

  const getMonth = (monthIndex) => {
    const months = [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ]
    return months[monthIndex]
  }

  const today = getDateToday()
  if (customDate) {
    const [year, month, day] = customDate.split('-')
    return `${day} ${getMonth(Number(month) - 1)} ${year}`
  }
  return `${today.day} ${getMonth(today.month)} ${today.year}`
}

function startCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default LetterPreviewBuilder
