/**
 * Custom hook to handle address autocomplete functionality.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.formContext - The form context to set form values.
 * @param {string} params.name - The name of the form field.
 * @param {Object} params.uiOptions - UI options for the autocomplete.
 * @param {boolean} params.isAddress - Flag to indicate if the field is an address.
 *
 * @returns {Object} - The state and handlers for address autocomplete.
 * @returns {boolean} isVisible - State to control the visibility of the autocomplete dropdown.
 * @returns {Function} setIsVisible - Function to set the visibility of the autocomplete dropdown.
 * @returns {Object} dropdownPosition - The position of the autocomplete dropdown.
 * @returns {Function} setDropdownPosition - Function to set the position of the autocomplete dropdown.
 * @returns {Array} predictionResults - The prediction results from Google Places API.
 * @returns {Function} handleSuggestionClick - Handler for suggestion click event.
 * @returns {Function} setAddressInputValue - Function to set the address input value.
 * @returns {Object|null} formattedAddress - The formatted address object.
 *
 * @example
 * const {
 *   isVisible,
 *   setIsVisible,
 *   dropdownPosition,
 *   setDropdownPosition,
 *   predictionResults,
 *   handleSuggestionClick,
 *   setAddressInputValue,
 *   formattedAddress,
 * } = useAddressAutocomplete({
 *   formContext,
 *   name: 'address',
 *   uiOptions: { autoCompleteAddress: [{ fieldName: 'street', type: 'street' }] },
 *   isAddress: true,
 * })
 */
import { COUNTRIES } from '@/utils/constants'
import { useEffect, useState } from 'react'
import { useGooglePlaces } from './useGooglePlaces'

export const useAddressAutocomplete = ({
  formContext,
  name,
  uiOptions,
  isAddress,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 })

  const {
    inputValue: addressInputValue,
    setInputValue: setAddressInputValue,
    formattedAddress,
    predictionResults,
    handleSuggestionClick,
  } = useGooglePlaces()

  const getCountryCode = (country) => {
    const foundCountry = COUNTRIES.find(
      (c) => c.label.toLowerCase() === country.toLowerCase()
    )
    return foundCountry?.value ?? 'IT'
  }

  const getAddressFieldValue = (address, type) => {
    switch (type) {
      case 'street':
        return address.street
      case 'streetNumber':
        return address.streetNumber
      case 'postalCode':
        return address.postalCode
      case 'city':
        return address.city
      case 'state':
        return address.state
      case 'province':
        return address.province
      case 'country':
        return address.country === 'Italia'
          ? 'IT'
          : getCountryCode(address.country)
      default:
        return ''
    }
  }

  useEffect(() => {
    if (
      isAddress &&
      formattedAddress !== null &&
      formContext &&
      uiOptions?.autoCompleteAddress !== undefined
    ) {
      const isEditFieldName = name.includes('edit')
      const fieldToAutoFill = uiOptions?.autoCompleteAddress ?? []

      fieldToAutoFill.forEach(({ fieldName: _fieldName, type }) => {
        const fieldName = `${_fieldName}${isEditFieldName ? '-edit' : ''}`
        const value = getAddressFieldValue(formattedAddress, type)
        formContext.setValue(fieldName, value)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedAddress, isAddress, name, uiOptions?.autoCompleteAddress])

  return {
    isVisible,
    setIsVisible,
    dropdownPosition,
    setDropdownPosition,
    predictionResults,
    handleSuggestionClick,
    setAddressInputValue,
    formattedAddress,
  }
}
